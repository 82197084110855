/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useAnimations, PerspectiveCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

const color = new THREE.Color();

export default function Model({ scroll, ...props }) {
  console.log(scroll);
  const t = useRef(0);
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/model.glb");
  const { actions, mixer } = useAnimations(animations, group);
  const [hovered, set] = useState();
  const extras = {
    receiveShadow: true,
    castShadow: true,
    "material-envMapIntensity": 0.2,
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  useEffect(() => void actions["CameraAction.005"].play(), [actions]);
  useEffect(() => {
    if (hovered)
      group.current.getObjectByName(hovered).material.color.set("white");
    document.body.style.cursor = hovered ? "pointer" : "auto";
  }, [hovered]);
  useFrame((state) => {
    mixer.setTime(
      (t.current = THREE.MathUtils.lerp(
        t.current,
        actions["CameraAction.005"]._clip.duration * scroll.current,
        0.05
      ))
    );
    group.current.children[0].children.forEach((child, index) => {
      child.material.color.lerp(
        color
          .set(hovered === child.name ? "#72bcd4" : "#202020")
          .convertSRGBToLinear(),
        hovered ? 0.1 : 0.05
      );
      const et = state.clock.elapsedTime;
      child.position.y = Math.sin((et + index * 2000) / 2) * 1;
      child.rotation.x = Math.sin((et + index * 2000) / 3) / 10;
      child.rotation.y = Math.cos((et + index * 2000) / 2) / 10;
      child.rotation.z = Math.sin((et + index * 2000) / 3) / 10;
    });
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group
        onPointerOver={(e) => (e.stopPropagation(), set(e.object.name))}
        onPointerOut={(e) => (e.stopPropagation(), set(null))}
        position={[0.06, 4.04, 0.35]}
        scale={[0.25, 0.25, 0.25]}
      >
        <mesh
          name="Notebook"
          geometry={nodes.Notebook.geometry}
          material={materials.M_Notebook}
          onClick={() => openInNewTab("https://github.com/jsip/miningGame")}
          {...extras}
        />
        <mesh
          name="Headphones"
          geometry={nodes.Headphones.geometry}
          material={materials.M_Headphone}
          onClick={() => openInNewTab("https://github.com/jsip/watchl")}
          {...extras}
        />
        <mesh
          name="Rocket003"
          geometry={nodes.Rocket003.geometry}
          material={materials.M_Rocket}
          onClick={() => openInNewTab("https://getmille.com/")}
          {...extras}
        />
        <mesh
          name="Roundcube001"
          geometry={nodes.Roundcube001.geometry}
          material={materials.M_Roundcube}
          onClick={() => openInNewTab("https://github.com/jsip/lightflix")}
          {...extras}
        />
        <mesh
          name="Table"
          geometry={nodes.Table.geometry}
          material={materials.M_Table}
          onClick={() => openInNewTab("https://github.com/jsip/RAMDashboard")}
          {...extras}
        />
        <mesh
          name="VR_Headset"
          geometry={nodes.VR_Headset.geometry}
          material={materials.M_Headset}
          onClick={() =>
            openInNewTab(
              "https://www.linkedin.com/in/jean-simon-royer-453a38196/"
            )
          }
          {...extras}
        />
        <mesh
          name="Zeppelin"
          geometry={nodes.Zeppelin.geometry}
          material={materials.M_Zeppelin}
          onClick={() => openInNewTab("https://github.com/jsip/portfolio")}
          {...extras}
        />
      </group>
      <group
        name="Camera"
        position={[-1.78, 2.04, 23.58]}
        rotation={[1.62, 0.01, 0.11]}
      >
        <PerspectiveCamera
          makeDefault
          far={100}
          near={1}
          fov={25}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <directionalLight
            castShadow
            position={[10, 20, 15]}
            shadow-camera-right={8}
            shadow-camera-top={8}
            shadow-camera-left={-8}
            shadow-camera-bottom={-8}
            shadow-mapSize-width={1024}
            shadow-mapSize-height={1024}
            intensity={2}
            shadow-bias={-0.0001}
          />
        </PerspectiveCamera>
      </group>
    </group>
  );
}

useGLTF.preload("/model.glb");
